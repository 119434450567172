import React, { useState } from 'react';
import CodePromo from '../components/CodePromo';
import Button from '@material-ui/core/Button';

import '../styles/Sidebar.css';

function Sidebar(props) {
  const [coupon, setCoupon] = useState(null);
  const setParentCoupon = props.setCoupon;

  const submitCoupon = (value) => {
    // Setting local and parent state
    setCoupon(value);
    setParentCoupon(value);
  };

  const getCouponString = (coupon) => {
    if (coupon.amount_off) return `${coupon.amount_off / 100}€`;
    else if (coupon.percent_off) return `${coupon.percent_off}% `;
  };

  const getCouponPrice = (coupon, price) => {
    if (coupon.amount_off) return price - coupon.amount_off / 100;
    else if (coupon.percent_off) {
      return ((100 - coupon.percent_off) / 100) * price;
    }
  };

  return (
    <div className="card sidebar" style={{ minWidth: '312px' }}>
      <div className="card-header">Votre panier</div>
      <div className="card-body">
        <div className="price-block">
          <span className="label">Livraison</span>
          <span className="price">gratuite</span>
        </div>
        {!props.yearly && (
          <>
            <div className="price-block">
              <span className="label">Tous les 3 mois</span>
              <span className="price">{props.price * 3}€ HT</span>
            </div>
            <div className="price-block">
              <span className="label">Soit par mois</span>
              <span className="price">{props.price}€ HT</span>
            </div>
          </>
        )}
        {!props.yearly && coupon && (
          <>
            <div className="price-block">
              <span className="label blue" style={{ fontWeight: 'bold' }}>
                Réduction
              </span>
              <span className="price blue" style={{ border: 'none' }}>
                -{getCouponString(coupon)}
              </span>
            </div>
            <div className="price-block">
              <span className="label blue" style={{ fontWeight: 'bold' }}>
                {coupon.duration === 'forever'
                  ? 'Chaque prélevement'
                  : 'Premier prélevement'}
              </span>
              <span className="price blue" style={{ border: 'none' }}>
                {getCouponPrice(coupon, props.price * 3).toFixed(0)}€ HT
              </span>
            </div>
          </>
        )}
        {props.yearly && (
          <div>
            <div className="price-block">
              <span className="label">A payer chaque année</span>
              <span className="price">{props.price * 12}€ HT</span>
            </div>
            <div className="price-block">
              <span className="label">Soit par mois</span>
              <span className="price">{props.price}€ HT</span>
            </div>
          </div>
        )}
        {props.yearly && coupon && (
          <>
            <div className="price-block">
              <span className="label blue" style={{ fontWeight: 'bold' }}>
                Réduction
              </span>
              <span className="price blue" style={{ border: 'none' }}>
                -{getCouponString(coupon)}
              </span>
            </div>
            <div className="price-block">
              <span className="label blue" style={{ fontWeight: 'bold' }}>
                {coupon.duration === 'forever'
                  ? 'Chaque prélevement'
                  : 'Premier prélevement'}
              </span>
              <span className="price blue" style={{ border: 'none' }}>
                {getCouponPrice(coupon, props.price * 12).toFixed(0)}€ HT
              </span>
            </div>
          </>
        )}
        {!coupon && props.couponDisplayed && <hr />}
        {!coupon && props.couponDisplayed && (
          <CodePromo
            submit={(value) => {
              submitCoupon(value);
            }}
          />
        )}
        <hr />
        <div className="block-items">
          <div className="block first">
            <i className="icon icofont-lock"></i>
            <span className="label">Paiement 100% sécurisé</span>
          </div>
          <div className="block second">
            <i className="icon icofont-ui-calendar"></i>
            <span className="label">30 jours pour changer d'avis</span>
          </div>
          <div className="block third">
            <i className="icon fas fa-shipping-fast"></i>
            <span className="label">Livraison gratuite</span>
          </div>
          <div className="block fourth">
            <i className="icon icofont-link-broken"></i>
            <span className="label">Sans engagement</span>
          </div>
        </div>
        {props.nextDisplayed && (
          <div>
            <hr />
            <Button
              className="form-submit"
              color="secondary"
              fullWidth
              disableElevation
              variant="contained"
              onClick={props.nextStep}
              disabled={!props.nextEnabled}
            >
              Suivant
            </Button>
          </div>
        )}
      </div>
    </div>
  );
}

export default Sidebar;
