import React from 'react';

function Payment(props) {
    return(
        <div className="card main-block contrat-block animated fadeIn">
            <div className="card-header">Confirmation</div>
            <div className="card-body">
                <section style={{padding: '20px'}}>
                    <i className="icofont-checked blue" style={{display: 'block', fontSize: '5em', textAlign: 'center'}}></i>
                    <hr/>
                    <p>
                        Merci pour votre confiance. <i className="far fa-smile"></i>
                        <br />
                        <br />
                        Un récapitulatif de votre commande vous a été envoyé à l'adresse suivante <b>{props.mail}</b>.
                        <br />
                        <br />
                        Notre équipe configure votre Tablette Ranky pour vous.
                        <br />
                        Elle sera expédiée sous 72h.
                        <br />
                        <br />
                        Vous trouverez ci dessous votre mandat de prélévement SEPA : 
                    </p>
                    <div style={{textAlign: 'center'}}>
                        <a target="_blank" rel="noopener noreferrer" href={props.mandat} className="btn btn-primary">Mandat SEPA</a>
                    </div>
                </section>
            </div>
        </div>
    );
}

export default Payment;