import React from 'react';
import { Elements } from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';

import IBANForm from './IBANForm';

import '../styles/Payment.css';

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC, {locale: 'fr'});


function Payment(props) {
    if (!props.client) {
        return ( 
        <div className="card main-block contrat-block animated fadeIn">
            <div className="card-header">Paiement</div>
            <div className="card-body">
                <div className="alert alert-error">
                    Une erreur est survenue. Merci de nous contacter.
                </div>
            </div>
        </div>
);
    }

    return(
        <div className="card main-block contrat-block animated fadeIn">
            <div className="card-header">Mandat SEPA</div>
            <div className="card-body">
                <Elements stripe={stripePromise}>
                    <IBANForm
                        client={props.client}
                        plan={props.plan}
                        coupon={props.coupon}
                        handleSubmit={props.handleSubmit}
                        yearly={props.yearly}
                    />
                </Elements>

            </div>
        </div>
    );
}

export default Payment;