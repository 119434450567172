import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Input from '@material-ui/core/Input';
import { getUrlVars } from '../App';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '2px 0px',
    display: 'flex',
    alignItems: 'center',
  },
  input: {
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  divider: {
    height: 28,
    margin: 4,
  },
}));

function CodePromo(props) {
  const classes = useStyles();
  const [value, setValue] = useState('');
  const [error, setError] = useState(null);

  const handleSubmit = useCallback(
    (e, forcedValue) => {
      const sent = forcedValue ? forcedValue : value;

      axios
        .get(`${process.env.REACT_APP_API_URL}/coupon/${sent}`)
        .then((res) => {
          setError(null);
          if (props.submit) props.submit(res.data);
        })
        .catch((err) => {
          setValue('');
          setError("Ce code n'est pas/plus valide");
        });
    },
    [props, value],
  );

  useEffect(() => {
    const urlParams = getUrlVars();
    if (urlParams.coupon && !value) {
      setValue(urlParams.coupon);
      handleSubmit({}, urlParams.coupon);
    }
  }, [handleSubmit, value]);

  return (
    <div>
      <p className="mb-0">Vous avez un code promo ?</p>
      <div className={classes.root}>
        <Input
          className={classes.input}
          placeholder="Code Promo"
          value={value}
          onChange={(e) => {
            setValue(e.target.value);
          }}
        />
        <Button
          color="primary"
          className={classes.iconButton}
          aria-label="directions"
          onClick={handleSubmit}
        >
          OK
        </Button>
      </div>
      {error && (
        <div>
          <p>{error}</p>
        </div>
      )}
    </div>
  );
}

export default CodePromo;
