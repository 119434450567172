import React, { useState, useEffect } from 'react';
import { useWindowSize } from './hooks';

import Stepper from './components/Stepper';
import Sidebar from './components/Sidebar';
// import Recap from './components/Recap';
import Contrat from './components/Contrat';
import Payment from './components/Payment';
import Confirmation from './components/Confirmation';

// Plans
import plans from './plans.json';

// Material UI
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import green from '@material-ui/core/colors/green';

// CSS
import 'bootstrap/dist/css/bootstrap.min.css';
import '@fortawesome/fontawesome-free/css/all.css';
import 'animate.css/animate.min.css';
import './vendor/icofont.min.css';
import './styles/App.css';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#0176FF',
    },
    secondary: green,
  },
  status: {
    danger: 'orange',
  },
});

export const getUrlVars = () => {
  var vars = {};
  window.location.href.replace(
    /[?&]+([^=&]+)=([^&]*)/gi,
    function (m, key, value) {
      vars[key] = value;
    },
  );
  return vars;
};

function App() {
  const [step, setStep] = useState(0);
  const size = useWindowSize();
  const [plan, setPlan] = useState(plans[0]);
  const [yearly, setYearly] = useState(false);

  const [values, setValues] = useState({
    contrat: null,
    payment: null,
    coupon: null,
  });

  // Select plan from URL
  useEffect(() => {
    const urlParams = getUrlVars();
    if (urlParams.plan && getPlanFromId(urlParams.plan)) {
      setPlan(getPlanFromId(urlParams.plan));
    }
    if (urlParams.year && urlParams.year === 'true') {
      setYearly(true);
    }
  }, [plan]);

  const handleNext = () => {
    setStep((prevStep) => prevStep + 1);
  };

  const handleContrat = (contractValues) => {
    setValues({ ...values, contrat: contractValues });
    handleNext();
  };

  const handlePayment = (mandateUrl) => {
    setValues({ ...values, payment: { mandate: mandateUrl } });
    handleNext();
  };

  const setCoupon = (value) => {
    setValues({ ...values, coupon: value });
  };

  const getPlanFromId = (id) => {
    let res = null;
    for (let i = 0; i < plans.length; i++) {
      const element = plans[i];
      if (element.id === id) res = element;
    }
    return res;
  };

  return (
    <ThemeProvider theme={theme}>
      <div className="App">
        <Stepper activeStep={step} />
        <section className="pt-8 pt-md-11 pb-10 pb-md-15 bg-primary shape-wrapper">
          <div className="shape shape-top shape-fluid-x svg-shim text-white">
            <svg
              viewBox="0 0 1738 587"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              preserveAspectRatio="none"
            >
              <path
                d="M0 0H1420.92C1420.92 0 2134.35 457.505 1420.92 485.868C707.502 514.231 0 0 0 0Z"
                fill="url(#paint0_linear)"
              ></path>
              <defs>
                <linearGradient
                  id="paint0_linear"
                  x1="0"
                  y1="0"
                  x2="1049.98"
                  y2="912.68"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stopColor="currentColor" stopOpacity="0.075"></stop>
                  <stop
                    offset="1"
                    stopColor="currentColor"
                    stopOpacity="0"
                  ></stop>
                </linearGradient>
              </defs>
            </svg>
          </div>
        </section>

        <div
          className={
            size.width <= 991
              ? 'container-fluid shape-up'
              : 'container shape-up'
          }
        >
          <div className="row">
            <div className="col-md-12 col-lg-8">
              {/* {step === 0 && <Recap plan={plan} yearly={yearly} />} */}
              {step === 0 && (
                <Contrat plan={plan} handleSubmit={handleContrat} />
              )}
              {step === 1 && (
                <Payment
                  plan={plan}
                  client={values.contrat}
                  coupon={values.coupon}
                  handleSubmit={handlePayment}
                  yearly={yearly}
                />
              )}
              {step === 2 && (
                <Confirmation
                  mail={values.contrat.email || ''}
                  mandat={values.payment.mandate || ''}
                />
              )}
            </div>
            <div className="col-md-12 col-lg-4">
              <Sidebar
                yearly={yearly}
                nextStep={handleNext}
                nextEnabled={true}
                nextDisplayed={step === 0}
                price={yearly ? plan.yearlyMonthPrice : plan.price}
                couponDisplayed={step !== 3}
                setCoupon={setCoupon}
              />
            </div>
          </div>
        </div>
      </div>
    </ThemeProvider>
  );
}

export default App;
