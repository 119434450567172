import React, {useState, useEffect} from 'react';
import { useForm } from "react-hook-form";
import countries from 'i18n-iso-countries';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputLabel from '@material-ui/core/InputLabel';
import Button from '@material-ui/core/Button';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import axios from 'axios';
import $ from 'jquery';


import FindPlace from './FindPlace';


import '../styles/Contrat.css';
    

function Contrat(props) {
    const [values, setValues] = useState({
        email: '',
        name: '',
        tel: '',
        placeAddress: '',
        placeId: '',
        latLng: null,
        tripAdvisor: false,
        billAddress: '',
        billZipCode: '',
        billCity: '',
        billCountry: 'FR'
    });
    const [countriesList, setCountriesList] = useState({});
    const { handleSubmit, register, errors } = useForm();


    useEffect(() => {
        countries.registerLocale(require("i18n-iso-countries/langs/fr.json"))
        setCountriesList(countries.getNames("fr"))
    }, [countriesList]);


    const onSubmit = formValues => {
        axios.post(`${process.env.REACT_APP_API_URL}/form`, formValues);
        props.handleSubmit(Object.assign({}, values, formValues));
    };
    
    const handleOnChange = event => {
        const { name, value } = event.target;
        setValues({ ...values, [name]: value });
    };

    const initMap = (lat, lng) => {
        var place = {lat, lng};
        $('#map').show();
        var map = new window.google.maps.Map(
            document.getElementById('map'), {
                zoom: 16,
                center: place,
                draggable: false,
                gestureHandling: 'none',
                streetViewControl: false,
                clickableIcons: false
            });
        new window.google.maps.Marker({position: place, map: map});
      }
      

    const selectPlace = (selected, placeId, latLng) => {
        setValues({...values, placeAddress: selected, placeId, latLng})
        initMap(latLng.lat, latLng.lng);
    };

    const getCountriesSelectItems = () => {
        let res = [];
        for (const key in countriesList) {
            if (countriesList.hasOwnProperty(key)) {
                const element = countriesList[key];
                res.push(<MenuItem key={key} value={key}>{element}</MenuItem>)
            }
        }
        return res;
    }

    const billingAdressForm = (
        <div>
            <section>
                <h5 className="section-title">
                    Votre adresse de facturation
                </h5>
                <div className="row">
                    <div className="col-md-12">
                        <FormControl fullWidth  variant="outlined" className={(!errors.billAddress && values.billAddress.length) ? 'validated' : ''}>
                            <InputLabel>N° et nom de voie</InputLabel>
                            <OutlinedInput
                                fullWidth
                                label="N° et nom de voie"
                                name="billAddress"
                                inputRef={register({
                                    required: 'Ce champ est requis',
                                })}
                                error={!!errors.billAddress}
                                onChange={handleOnChange}
                                value={values.billAddress}
                            />
                            <FormHelperText id="component-helper-text">{errors.billAddress && errors.billAddress.message}  </FormHelperText>
                        </FormControl>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-4">
                        <FormControl fullWidth  variant="outlined" className={(!errors.billZipCode && values.billZipCode.length) ? 'validated' : ''}>
                            <InputLabel>Code Postal</InputLabel>
                            <OutlinedInput
                                fullWidth
                                label="Code Postal"
                                name="billZipCode"
                                // inputRef={register({
                                //     required: 'Ce champ est requis',
                                //     pattern: {
                                //     value: /^(([0-8][0-9])|(9[0-5]))[0-9]{3}$/i,
                                //     message: "Ce code postal est invalide"
                                //     }
                                // })}
                                // error={!!errors.billZipCode}
                                onChange={handleOnChange}
                                value={values.billZipCode}
                            />
                            <FormHelperText id="component-helper-text">{errors.billZipCode && errors.billZipCode.message}  </FormHelperText>
                        </FormControl>
                    </div>
                    <div className="col-md-4">
                        <FormControl fullWidth  variant="outlined" className={(!errors.billCity && values.billCity.length) ? 'validated' : ''}>
                            <InputLabel>Ville</InputLabel>
                            <OutlinedInput
                                fullWidth
                                inputProps={{type : "billCity"}}
                                label="Ville"
                                name="billCity"
                                inputRef={register({
                                    required: 'Ce champ est requis',
                                })}
                                error={!!errors.billCity}
                                onChange={handleOnChange}
                                value={values.billCity}
                            />
                            <FormHelperText id="component-helper-text">{errors.billCity && errors.billCity.message}  </FormHelperText>
                        </FormControl>
                    </div>
                    <div className="col-md-4">
                        <FormControl fullWidth  variant="outlined" className="validated">
                            <InputLabel>Pays</InputLabel>
                            <Select
                            name="billCountry"
                            label="Pays"
                            value={values.billCountry}
                            onChange={handleOnChange}
                            >
                                {getCountriesSelectItems()}
                            </Select>
                        </FormControl>
                    </div>
                </div>
            </section>
        </div>
    )

    const coordForm = (
        <section>
            <h5 className="section-title">
                Vos coordonnées
            </h5>
            <div className="row">
                <div className="col-md-12">
                    <FormControl fullWidth  variant="outlined" className={(!errors.name && values.name.length) ? 'validated' : ''}>
                        <InputLabel>Votre nom ou raison sociale</InputLabel>
                        <OutlinedInput
                            fullWidth
                            label="Votre nom ou raison sociale"
                            name="name"
                            inputRef={register({
                                required: 'Ce champ est requis',
                            })}
                            error={!!errors.name}
                            onChange={handleOnChange}
                            value={values.name}
                        />
                        <FormHelperText id="component-helper-text">{errors.name && errors.name.message}  </FormHelperText>
                    </FormControl>
                </div>
            </div>
            <div className="row">
                <div className="col-md-6">
                    <FormControl fullWidth  variant="outlined" className={(!errors.email && values.email.length) ? 'validated' : ''}>
                        <InputLabel>Adresse e-mail</InputLabel>
                        <OutlinedInput
                            fullWidth
                            label="Adresse e-mail"
                            name="email"
                            inputRef={register({
                                required: 'Ce champ est requis',
                                pattern: {
                                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                message: "Cette adresse e-mail est invalide"
                                }
                            })}
                            error={!!errors.email}
                            onChange={handleOnChange}
                            value={values.email}
                        />
                        <FormHelperText id="component-helper-text">{errors.email && errors.email.message}  </FormHelperText>
                    </FormControl>
                </div>
                <div className="col-md-6">
                    <FormControl fullWidth  variant="outlined" className={(!errors.tel && values.tel.length) ? 'validated' : ''}>
                        <InputLabel>Téléphone</InputLabel>
                        <OutlinedInput
                            fullWidth
                            inputProps={{type : "tel"}}
                            label="Téléphone"
                            name="tel"
                            inputRef={register({
                                required: 'Ce champ est requis',
                                pattern: {
                                value:  /^((\+)33|0|0033)[1-9](\d{2}){4}$/g,
                                message: "Ce numéro de téléphone n'est pas valide"
                                }
                            })}
                            error={!!errors.tel}
                            onChange={handleOnChange}
                            value={values.tel}
                        />
                        <FormHelperText id="component-helper-text">{errors.tel && errors.tel.message}  </FormHelperText>
                    </FormControl>
                </div>
            </div>
        </section>
    );

    const placeForm = (
        <section>
            <h5 className="section-title">
                Votre établissement
            </h5>
            <input
                className="hidden"
                name="placeId"
                ref={register({
                required: 'Veuillez sélectionner votre établissement ci-dessous',
                })}
                value={values.placeId}
                readOnly
            />
            {errors.placeId && errors.placeId.message && (
                <div className="alert alert-warning">
                    {errors.placeId && errors.placeId.message}
                </div>
            )}

            {
                values.placeAddress.length
                ?(
                    <div>
                        <p>Vous avez sélectionné :</p>
                        <h6 style={{textAlign: 'center'}}>
                            <i className="icofont-google-map blue"></i>
                            &nbsp;
                            {values.placeAddress}
                            <button className="btn btn-link" onClick={() => {setValues({...values, placeAddress: ''})}}>
                                <i className="fa fa-times"></i>
                            </button>
                        </h6>
                        <div className="map hidden" id="map"></div>
                        <br/>
                        <FormGroup row className="billdiff-wrapper">
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={values.tripAdvisor}
                                        onChange={(e) => {setValues({...values, tripAdvisor: e.target.checked})}}
                                        color="primary"
                                    />
                                }
                                label={<span>Cet établissement est également sur <b className="ta-green"><i className="fab fa-tripadvisor"/>&nbsp;TripAdvisor</b></span>}
                            />
                        </FormGroup>
                    </div>
                )
                :(
                    <div>
                        <p>
                            Veuillez sélectionner l'établissement dans lequel sera installée la tablette dans la liste déroulante ci-dessous.
                        </p>
                        <FindPlace setSelected={selectPlace} />
                    </div>
                )
            }
        </section>
    );

    return(
        <div className="card main-block contrat-block animated fadeIn">
            <div className="card-header">Vos informations</div>
            <div className="card-body">
                <form  className="contrat-form" onSubmit={handleSubmit(onSubmit)}>
                    {placeForm}
                    <hr/>
                    {billingAdressForm}
                    <hr/>
                    {coordForm}
                    <hr/>
                    <div>
                            <Button
                                className="form-submit"
                                disableElevation
                                type="submit"
                                variant="contained"
                                size="large"
                                color="secondary"
                                style={{ marginTop: '30px' }}
                            >
                                Suivant
                            </Button>
                    </div>

                </form>

            </div>
        </div>

    );
}

export default Contrat;