// This example shows you how to set up React Stripe.js and use Elements.
// Learn how to accept a SEPA Debit payment using the offical Stripe docs.
// https://stripe.com/docs/payments/sepa-debit/accept-a-payment

import React, {useState} from 'react';
import axios from 'axios';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import {IbanElement, useElements, useStripe} from '@stripe/react-stripe-js';

import '../styles/Stripe.css';


const ELEMENT_OPTIONS = {
  supportedCountries: ['SEPA'],
  style: {
    base: {
      fontSize: '18px',
      color: '#424770',
      letterSpacing: '0.025em',
      '::placeholder': {
        color: '#aab7c4',
      },
    },
    invalid: {
      color: '#9e2146',
    },
  },
};

const IBANForm = (props) => {
  const stripe = useStripe();
  const elements = useElements();
  const [errorMessage, setErrorMessage] = useState(null);
  const [cgv, setCGV] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (event) => {
    setLoading(true);
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();

    if (!stripe || !elements || !props.client) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }
    try {
      const ibanElement = elements.getElement(IbanElement);
  
      const paymentMethod = await stripe.createPaymentMethod({
        type: 'sepa_debit',
        sepa_debit: ibanElement,
        billing_details: {
          address: {
              line1: props.client.billAddress,
              city: props.client.billCity,
              country: props.client.billCountry,
              postal_code: props.client.billZipCode
          },
          email: props.client.email,
          name: props.client.name,
          phone: props.client.tel
        },
      });
  
      if (paymentMethod.error) {
        setErrorMessage(paymentMethod.error.message);
        throw(paymentMethod.error.message);
      } else {
        setErrorMessage(null);
        const token = await (await axios.post(`${process.env.REACT_APP_API_URL}/payment/iban/intent`, {client: props.client})).data.token;
    
        const result = await stripe.confirmSepaDebitSetup(token, {
          payment_method: paymentMethod.paymentMethod.id
        });
    
        if (result.error) {
          // Show error to your customer.
          setErrorMessage(result.error.message)
        } else {
          if (result.setupIntent.status === 'succeeded') {
            const mandateUrl = await (await axios.post(`${process.env.REACT_APP_API_URL}/payment/confirm/${props.plan.id}`, {intentId: result.setupIntent.id, coupon: props.coupon, yearly: props.yearly})).data.mandate;
            props.handleSubmit(mandateUrl)
          }
          // Show a confirmation message to your customer.
          // The SetupIntent is in the 'succeeded' state.
        }
  
      }
    } catch (error) {
      setLoading(false);
      setErrorMessage('Une erreur est survenue. Merci de nous contacter.')
    }

  };

  return (
    <form onSubmit={handleSubmit} className="iban-form">
      <section>
        <h5 className="section-title">Coordonnées Bancaires</h5>
        <hr/>
        <div className="alert alert-success">
          <ul>
            <li>Votre numéro IBAN est crypté et transmis de manière sécurisée à notre service bancaire.</li>
            <li>Ranky SAS est autorisé par la Banque de France à émettre des prélèvement auprès de ses clients.</li>
            <li>Aucune caution ne sera prélevée sur votre compte à la souscription pour le matériel mis à votre disposition.</li>
          </ul>

        </div>
        <h6 className="blue mt-5">Veuillez renseigner votre IBAN : </h6>
        <IbanElement
          id="iban"
          options={ELEMENT_OPTIONS}
        />
        {
            errorMessage && (
              <div className="alert alert-danger">
                  {errorMessage}
              </div>
            )
        }
        <hr/>
        <h5 className="section-title mt-5">Conditions</h5>
        <FormGroup row >
            <FormControlLabel
                labelPlacement="end"
                control={
                    <Checkbox
                        checked={cgv}
                        onChange={(e) => {setCGV(e.target.checked)}}
                        color="primary"
                    />
                }
                label={<span>Je reconnais avoir pris connaissance et accepter les <a target="_blank" rel="noopener noreferrer" href="https://ranky.s3-eu-west-1.amazonaws.com/CONDITIONS+GE%CC%81NE%CC%81RALES+DE+LOCATION.pdf">Conditions Générales de Location</a>,
                  ainsi que la <a target="_blank" rel="noopener noreferrer" href="https://ranky.io/confidentialite/">politique de confidentialité</a>.
                 </span>}
            />
        </FormGroup>
        <div className="alert alert-light mt-2" style={{textAlign: 'justify'}}>
          J'ai vérifié l'exactitude des informations renseignées.
          En confirmant je donne mandat à Ranky pour prélever mes factures d'abonnement
          En validant votre IBAN, vous autorisez Ranky SAS à envoyer des instructions à votre banque pour débiter votre compte, et votre banque à débiter votre compte conformément aux instructions du Créancier. Vous bénéficiez du droit d'être remboursé par votre banque selon les conditions décrites dans la convention que vous avez passée avec elle. Vos droits concernant le présent mandat sont expliqués dans un document que vous pouvez obtenir auprès de votre banque.
        </div>
        <Button
              className="form-submit"
              disableElevation
              type="submit"
              variant="contained"
              size="large"
              color="secondary"
              fullWidth
              style={{ marginTop: '5px', marginBottom: '20px' }}
              disabled={!stripe || !cgv || loading}
          >
            {!loading ? 'Confirmer' : <i className="fas fa-sync" />}
          </Button>
      </section>
    </form>
  );
};


export default IBANForm;