import React from 'react';
import clsx from 'clsx';
import { makeStyles, withStyles } from '@material-ui/core/styles';

import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepConnector from '@material-ui/core/StepConnector';
import Check from '@material-ui/icons/Check';

import '../styles/Stepper.css';

import logo from '../assets/logo-blue.png';

const QontoConnector = withStyles({
  alternativeLabel: {
    top: 10,
    left: 'calc(-50% + 16px)',
    right: 'calc(50% + 16px)',
  },
  active: {
    '& $line': {
      borderColor: '#0176FF',
    },
  },
  completed: {
    '& $line': {
      borderColor: '#0176FF',
    },
  },
  line: {
    borderColor: '#eaeaf0',
    borderTopWidth: 3,
    borderRadius: 1,
  },
})(StepConnector);

const useQontoStepIconStyles = makeStyles({
  root: {
    color: '#eaeaf0',
    display: 'flex',
    height: 22,
    alignItems: 'center',
  },
  active: {
    color: '#0176FF',
  },
  circle: {
    width: 8,
    height: 8,
    borderRadius: '50%',
    backgroundColor: 'currentColor',
  },
  completed: {
    color: '#0176FF',
    zIndex: 1,
    fontSize: 18,
  },
});

function QontoStepIcon(props) {
  const classes = useQontoStepIconStyles();
  const { active, completed } = props;

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
      })}
    >
      {completed ? (
        <Check className={classes.completed} />
      ) : (
        <div className={classes.circle} />
      )}
    </div>
  );
}

function StepperComponent(props) {
  return (
    <div className="top-stepper shadowed">
      <div className="row" style={{ marginLeft: '0px', marginRight: '0px' }}>
        <div className="col-md-3 logo-wrapper">
          <a href="https://ranky.io" target="_blank" rel="noopener noreferrer">
            <img className="logo" src={logo} alt="Ranky" />
          </a>
        </div>
        <div className="col-md-9">
          <Stepper
            activeStep={props.activeStep}
            alternativeLabel
            connector={<QontoConnector />}
          >
            {/* <Step key={'recap'}>
                            <StepLabel StepIconComponent={QontoStepIcon}><span className={props.activeStep >= 0 ? 'blue' : ''}>Récapitulatif</span></StepLabel>
                        </Step> */}
            <Step key={'contrat'}>
              <StepLabel StepIconComponent={QontoStepIcon}>
                <span className={props.activeStep >= 0 ? 'blue' : ''}>
                  Contrat
                </span>
              </StepLabel>
            </Step>
            <Step key={'paiement'}>
              <StepLabel StepIconComponent={QontoStepIcon}>
                <span className={props.activeStep >= 1 ? 'blue' : ''}>
                  Paiement
                </span>
              </StepLabel>
            </Step>
            <Step key={'confirmation'}>
              <StepLabel StepIconComponent={QontoStepIcon}>
                <span className={props.activeStep >= 2 ? 'blue' : ''}>
                  Confirmation
                </span>
              </StepLabel>
            </Step>
          </Stepper>
        </div>
      </div>
    </div>
  );
}

export default StepperComponent;
