import React from 'react';
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
 } from 'react-places-autocomplete';
import { classnames } from '../vendor/mapsSearchHelpers';

import '../styles/FindPlace.css';

class SearchBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      address: '',
      errorMessage: '',
    };
  }

  handleChange = address => {
    this.setState({
      address,
      errorMessage: '',
    });
  };

  handleSelect = (selected , placeId) => {
    geocodeByAddress(selected)
    .then(results => getLatLng(results[0]))
    .then(latLng => {
      if (this.props.setSelected) {
        this.props.setSelected(selected, placeId, latLng);
      }
    }).catch(error => console.error('Error', error));

    this.setState({
      address : selected
    });
  };

  handleCloseClick = () => {
    this.setState({
      address: '',
    });
  };

  handleError = (status, clearSuggestions) => {
    this.setState({ errorMessage: status }, () => {
      clearSuggestions();
    });
  };

  render() {
    const {
      address,
      //errorMessage,
    } = this.state;

    return (
      <div>
        <PlacesAutocomplete
          onChange={this.handleChange}
          value={address}
          onSelect={this.handleSelect}
          onError={this.handleError}
          shouldFetchSuggestions={address.length > 2}
        >
          {({ getInputProps, suggestions, getSuggestionItemProps }) => {
            return (
              <div className="gmaps_autocomplete_search-bar-container">
                <div className="gmaps_autocomplete_search-input-container">
                  <input
                    {...getInputProps({
                      placeholder: 'Rechercher votre établissement sur Google Maps ici',
                      className: 'gmaps_autocomplete_search-input',
                    })}
                  />
                  {this.state.address.length > 0 && (
                    <button
                      className="gmaps_autocomplete_clear-button"
                      onClick={this.handleCloseClick}
                    >
                      x
                    </button>
                  )}
                </div>
                {suggestions.length > 0 && (
                  <div className="gmaps_autocomplete_autocomplete-container">
                    {suggestions.map(suggestion => {
                      const className = classnames('gmaps_autocomplete_suggestion-item', {
                        'gmaps_autocomplete_suggestion-item--active': suggestion.active,
                      });

                      return (
                        /* eslint-disable react/jsx-key */
                        <div
                          {...getSuggestionItemProps(suggestion, { className })}
                        >
                          <strong>
                            {suggestion.formattedSuggestion.mainText}
                          </strong>{' '}
                          <small>
                            {suggestion.formattedSuggestion.secondaryText}
                          </small>
                        </div>
                      );
                      /* eslint-enable react/jsx-key */
                    })}
                    <div className="gmaps_autocomplete_dropdown-footer">
                      <div>
                        <img
                          src={require('../assets/powered_by_google_default.png')}
                          className="gmaps_autocomplete_dropdown-footer-image"
                          alt=""
                        />
                      </div>
                    </div>
                  </div>
                )}
              </div>
            );
          }}
        </PlacesAutocomplete>
        {/* {errorMessage.length > 0 && (
          <div className="gmaps_autocomplete_error-message">{this.state.errorMessage}</div>
        )} */}
      </div>
    );
  }
}

export default SearchBar;